<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        {{ $t('ReportOnline') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="exportLoading"
          class="d-none d-md-block"
          :disabled="exportLoading"
          @click="exportExcel"
        >
          {{ $t('excel') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          fab
          icon
          outlined
          :loading="exportLoading"
          :disabled="exportLoading"
          @click="exportExcel"
        >
          <v-icon>{{ icons.mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <DateFilters @onSendDate="serachReport" />
      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          class="py-0"
          lg="4"
        >
          <v-text-field
            v-model.trim="searchtext"
            dense
            outlined
            :label="$t('search')"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.id`]="{ index }">
          <span v-if="dataTableList.length==index + 1"></span>
          <span v-else>
            {{ index + 1 }}
          </span>
        </template>
        <template v-slot:[`item.order_create`]="{ item }">
          <span
            :class="item.order_create==$t('total')?'font-weight-bold primary--text':''"
          >{{ item.order_create }}</span>
        </template>
        <template v-slot:[`item.order_total`]="{ item }">
          <span
            :class="item.order_create==$t('total')?'font-weight-bold primary--text':''"
          >{{ item.order_total }}</span>
        </template>
        <template v-slot:[`item.point`]="{ item }">
          <span
            :class="item.order_create==$t('total')?'font-weight-bold primary--text':''"
          >{{ item.point }}</span>
        </template>
        <template v-slot:[`item.order_totalpay`]="{ item }">
          <span
            :class="item.order_create==$t('total')?'font-weight-bold primary--text':''"
          >{{ item.order_totalpay }}</span>
        </template>
        <template v-slot:[`item.credit`]="{ item }">
          <span
            :class="item.order_create==$t('total')?'font-weight-bold primary--text':''"
          >{{ item.credit }}</span>
        </template>
        <template v-slot:[`item.net_amount`]="{ item }">
          <span
            :class="item.order_create==$t('total')?'font-weight-bold primary--text':''"
          >{{ item.net_amount }}</span>
        </template>
        <template v-slot:[`item.commissions`]="{ item }">
          <span
            :class="item.order_create==$t('total')?'font-weight-bold primary--text':''"
          >{{ item.commissions }}</span>
        </template>
        <template v-slot:[`item.total_income`]="{ item }">
          <span
            :class="item.order_create==$t('total')?'font-weight-bold primary--text':''"
          >{{ item.total_income }}</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiFileExcelOutline } from '@mdi/js'
import DateFilters from '@/components/basicComponents/DateFilters.vue'
import useReportSaleSummary from './useReportSaleSummary'

export default {
  components: {
    DateFilters,

  },

  setup() {
    return {
      ...useReportSaleSummary(),
      icons: {
        mdiFileExcelOutline,
      },
    }
  },

}
</script>
