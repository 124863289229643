import { ref, watch } from '@vue/composition-api'
import { sumdatetime } from '@/plugins/filters'
import { i18n } from '@/plugins/i18n'
import { removeComma, reportSummarYonline } from '@/views/reports/useExcel'

export default function useReportSaleSummary() {
  const searchtext = ref('')
  const XLSX = require('xlsx')
  const dataTableList = ref([])
  const columns = ref([
    {
      text: '#', align: 'start', value: 'id', width: 30,
    },
    { text: i18n.t('receipt_number'), value: 'order_id', width: 120 },
    { text: i18n.t('customer'), value: 'customer_name', width: 200 },
    { text: i18n.t('list'), value: 'detail', width: 200 },
    { text: i18n.t('order_date'), value: 'order_create', width: 170 },
    {
      text: i18n.t('total_payment'), value: 'order_total', width: 130, align: 'end',
    },
    {
      text: i18n.t('discount_points'), value: 'point', width: 120, align: 'end',
    },
    {
      text: i18n.t('total_purchase'), value: 'order_totalpay', width: 150, align: 'end',
    },
    {
      text: `${i18n.t('commissions')} ${i18n.t('credit')}`, value: 'credit', width: 150, align: 'end',
    },
    {
      text: i18n.t('net_amount'), value: 'net_amount', width: 150, align: 'end',
    },
    {
      text: i18n.t('commissions'), value: 'commissions', width: 120, align: 'end',
    },
    {
      text: i18n.t('income_total'), value: 'total_income', width: 150, align: 'end',
    },
  ])
  const options = ref({})
  const loading = ref(false)
  const payload = ref({})
  const report = ref({})
  const exportLoading = ref(false)
  const dateStart = ref('')
  const dateEnd = ref('')
  const serachReport = async (start, end) => {
    loading.value = true
    if (start) {
      dateStart.value = start
    }
    if (end) {
      dateEnd.value = end
    }
    payload.value = {
      searchtext: searchtext.value,
      start: dateStart.value,
      end: dateEnd.value,
      lang: i18n.locale,
    }
    const { data } = await reportSummarYonline(payload.value)
    report.value = data || []
    dataTableList.value = data.list != null ? data.list : []
    dataTableList.value = dataTableList.value.map((item, i) => {
      item.order_create = sumdatetime(item.order_create, i18n.locale)

      return { ...item, number: i + 1 }
    })

    dataTableList.value.push({
      order_create: i18n.t('total'),
      order_total: data.order_total_sum,
      point: data.point_sum,
      order_totalpay: data.order_totalpay_sum,
      credit: data.credit_sum,
      net_amount: data.net_amount_sum,
      commissions: data.commissions_sum,
      total_income: data.total_income_sum,
    })
    options.value.page = 1
    options.value.itemsPerPage = -1
    loading.value = false
  }

  const exportExcel = async () => {
    exportLoading.value = true

    // this.dataExport = this.mapData(JSON.parse(JSON.stringify(this.data)));
    let dataExport = dataTableList.value.map((item, i) => {
      delete item.order_id_pri

      return item
    })
    dataExport = await removeComma(JSON.parse(JSON.stringify(dataExport)))
    const fileName = `${i18n.t('ReportOnline')}.xlsx`

    // เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx
    /*  รายงานวิเคราะห์รายการตรวจ */

    const Heading = [
      [`${i18n.t('ReportOnline')} `],
      [
        '#',
        `${i18n.t('receipt_number')}`,
        `${i18n.t('customer')}`,
        `${i18n.t('list')}`,
        `${i18n.t('order_date')}`,
        `${i18n.t('total_payment')}`,
        `${i18n.t('discount_points')}`,
        `${i18n.t('total_purchase')}`,
        `${i18n.t('commissions')} ${i18n.t('credit')}`,
        `${i18n.t('net_amount')}`,
        `${i18n.t('commissions')}`,
        `${i18n.t('income_total')}`,

      ],
    ]
    const ws = XLSX.utils.aoa_to_sheet(Heading)
    XLSX.utils.sheet_add_json(ws, dataExport, {
      header: [
        'number',
        'order_id',
        'customer_name',
        'detail',
        'order_create',
        'order_total',
        'point',
        'order_totalpay',
        'credit',
        'net_amount',
        'commissions',
        'total_income',
      ],
      skipHeader: true,
      origin: -1,
    })
    const wb = XLSX.utils.book_new()
    const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 20 } }]
    const wscols = [
      { wch: 6 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ]
    ws['!merges'] = merge
    ws['!cols'] = wscols
    XLSX.utils.book_append_sheet(
      wb,
      ws,
      `${i18n.t('ReportOnline')}`,
    )

    /* พิมร์ files */
    XLSX.writeFile(wb, fileName)
    setTimeout(() => {
      exportLoading.value = false
    }, 1500)
  }

  watch([searchtext], () => {
    serachReport()
  })

  return {
    searchtext,
    dataTableList,
    columns,
    options,
    loading,
    exportLoading,
    payload,
    report,
    exportExcel,
    serachReport,
  }
}
